var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"studentData"}},[_c('div',{staticClass:"search"},[_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":_vm.exportTable}},[_vm._v(" 导出Excel ")]),_c('div',[_c('el-select',{staticClass:"pr-10",attrs:{"slot":"prepend","placeholder":"请选择"},slot:"prepend",model:{value:(_vm.post.new),callback:function ($$v) {_vm.$set(_vm.post, "new", $$v)},expression:"post.new"}},[_c('el-option',{attrs:{"label":"全部","value":0}}),_c('el-option',{attrs:{"label":"新学员","value":1}}),_c('el-option',{attrs:{"label":"老学员","value":2}})],1),_c('el-input',{staticClass:"input-with-select",staticStyle:{"width":"310px"},attrs:{"placeholder":_vm.placeholder,"size":"medium"},model:{value:(_vm.post.search),callback:function ($$v) {_vm.$set(_vm.post, "search", $$v)},expression:"post.search"}},[_c('el-select',{attrs:{"slot":"prepend","placeholder":"请选择"},slot:"prepend",model:{value:(_vm.post.type),callback:function ($$v) {_vm.$set(_vm.post, "type", $$v)},expression:"post.type"}},[_c('el-option',{attrs:{"label":"学员名称","value":"1"}}),_c('el-option',{attrs:{"label":"邀请人","value":"2"}})],1)],1)],1)],1),_c('pagination2',{ref:"childDialogBox",attrs:{"option":_vm.post,"url":"/openClass/studentData"},on:{"complete":_vm.complete1},scopedSlots:_vm._u([{key:"default",fn:function({ tableData }){return [_c('el-table',{ref:"dialogTable",staticStyle:{"width":"100%"},attrs:{"header-cell-style":{
          background: 'rgba(245,245,245,1)',
          color: '#333333',
        },"data":tableData}},[_c('el-table-column',{attrs:{"label":"学员","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"membership"},[_c('div',{staticClass:"tu"},[_c('img',{staticStyle:{"width":"22px","height":"22px"},attrs:{"src":row.user_photo,"alt":""}})]),_c('div',{staticClass:"right",attrs:{"title":row.student_remarks
                    ? row.user_name + '(' + row.student_remarks + ')'
                    : row.user_name}},[_vm._v(" "+_vm._s(row.user_name)+" "),(row.student_remarks)?_c('span',[_vm._v(" ("+_vm._s(row.student_remarks)+") ")]):_vm._e()])])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"首次在线","prop":"c_time","min-width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.c_time,'YYYY-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"最后在线","prop":"visit_duration","min-width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.e_time,'YYYY-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"在线时长","prop":"visit_duration","min-width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeLength")(row.visit_duration))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"在线次数","prop":"count"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.count)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"邀请人","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.invitee)?_c('div',{staticClass:"membership"},[_c('div',{staticClass:"tu"},[_c('img',{staticStyle:{"width":"22px","height":"22px"},attrs:{"src":row.invitee_photo,"alt":""}})]),_c('div',{staticClass:"right",attrs:{"title":row.invitee_remarks
                    ? row.invitee + '(' + row.invitee_remarks + ')'
                    : row.invitee}},[_vm._v(" "+_vm._s(row.invitee)+" "),(row.invitee_remarks)?_c('span',[_vm._v(" ("+_vm._s(row.invitee_remarks)+") ")]):_vm._e()])]):_c('span',[_vm._v("-")])]}}],null,true)},[_c('template',{slot:"header"},[_c('div',{staticClass:"flex-center hidetext",staticStyle:{"float":"left","margin-top":"5px"}},[_vm._v(" 邀请人 "),_c('helpIcon',{staticStyle:{"margin-left":"6px"},attrs:{"prompt":"当学员被不同人邀请时，只显示最新的一个邀请人"}})],1)])],2),_c('el-table-column',{attrs:{"label":"类型","prop":"count"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.new==1)?_c('span',[_vm._v("新学员")]):_vm._e(),(row.new==2)?_c('span',[_vm._v("老学员")]):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","align":"right","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.detailRecord(row)}}},[_vm._v("详情")])]}}],null,true)})],1)]}}])}),_c('el-drawer',{attrs:{"title":"详情","size":"558px","visible":_vm.drawer},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_vm._v(" 详情 "),_c('span',{staticClass:"tip"},[_vm._v("学员使用多个设备同时在线，在线时长会重复记录")])]),(_vm.drawer)?_c('div',{staticClass:"drawer_contain"},[_c('div',{staticClass:"student_info"},[_c('div',{staticClass:"student"},[_c('div',{staticClass:"tu"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.selected.uphoto,"alt":""}})]),_c('div',{staticClass:"name",attrs:{"title":_vm.selected.student_remarks
                ? _vm.selected.uname + '(' + _vm.selected.student_remarks + ')'
                : _vm.selected.uname}},[_vm._v(" "+_vm._s(_vm.selected.uname)+" "),(_vm.selected.student_remarks)?_c('span',[_vm._v(" ("+_vm._s(_vm.selected.student_remarks)+") ")]):_vm._e()])]),_c('div',{staticClass:"btns flex-center"},[_c('el-button',{attrs:{"type":"text","size":"medium"},on:{"click":_vm.fenzu}},[_vm._v("分组")]),_c('span',{staticClass:"sp_driver"},[_vm._v("|")]),_c('tagselect',{attrs:{"tag":_vm.selected.tag,"uids":_vm.selected.uid,"isLive":true,"nothing":true},on:{"tagChange":_vm.tagChange}},[_c('el-button',{attrs:{"slot":"opencouse","type":"text","size":"medium"},slot:"opencouse"},[_vm._v(" 打标签 ")])],1)],1)]),_c('div',{staticClass:"list_contain"},[_c('pagination2',{ref:"childDialogBox",attrs:{"option":_vm.post,"url":"/openClass/studentDetails","allFn":true},on:{"complete":_vm.complete},scopedSlots:_vm._u([{key:"default",fn:function({ tableData }){return [_c('el-table',{ref:"dialogTable",staticStyle:{"width":"100%"},attrs:{"header-cell-style":{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              },"data":tableData}},[_c('el-table-column',{attrs:{"label":"进入时间","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.c_time,'YYYY-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"在线时长"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeLength")(row.visit_duration))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"在线终端"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("getuserFrom")(row.user_from))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"地理位置","prop":"province"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.province)+" ")]}}],null,true)})],1)]}}],null,false,1213413309)})],1)]):_vm._e()]),(_vm.showCategory)?_c('category',{attrs:{"groupList":_vm.options3,"selectedData":_vm.showCategory2},on:{"close":function($event){_vm.showCategory = false},"reset":_vm.reset}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }