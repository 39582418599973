<template>
  <div id="studentData">
    <div class="search">
      <el-button type="primary" size="medium" @click="exportTable">
        导出Excel
      </el-button>

      <div>
        <el-select class="pr-10" v-model="post.new" slot="prepend" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="新学员" :value="1"></el-option>
          <el-option label="老学员" :value="2"></el-option>
        </el-select>
  
        <el-input
          style="width:310px"
          :placeholder="placeholder"
          v-model="post.search"
          class="input-with-select"
          size="medium"
        >
          <el-select v-model="post.type" slot="prepend" placeholder="请选择">
            <el-option label="学员名称" value="1"></el-option>
            <el-option label="邀请人" value="2"></el-option>
          </el-select>
        </el-input>
        <!-- <el-input
          v-model="post.search"
          size="medium"
          style="width: 218px"
          placeholder="输入学员名称搜索"
        ></el-input> -->
      </div>
    </div>

    <pagination2
      :option="post"
      url="/openClass/studentData"
      ref="childDialogBox"
      @complete="complete1"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          ref="dialogTable"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column label="学员" min-width="100">
            <template slot-scope="{ row }">
              <div class="membership">
                <div class="tu">
                  <img
                    style="width: 22px; height: 22px"
                    :src="row.user_photo"
                    alt=""
                  />
                </div>
                <div
                  class="right"
                  :title="
                    row.student_remarks
                      ? row.user_name + '(' + row.student_remarks + ')'
                      : row.user_name
                  "
                >
                  {{ row.user_name }}

                  <span v-if="row.student_remarks">
                    ({{ row.student_remarks }})
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="首次在线" prop="c_time" min-width="90px">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}
            </template>
          </el-table-column>

          <el-table-column
            label="最后在线"
            prop="visit_duration"
            min-width="90px"
          >
            <template slot-scope="{ row }">
              {{ row.e_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}
            </template>
          </el-table-column>

          <el-table-column
            label="在线时长"
            prop="visit_duration"
            min-width="90px"
          >
            <template slot-scope="{ row }">
              {{ row.visit_duration | formatTimeLength }}
            </template>
          </el-table-column>

          <el-table-column label="在线次数" prop="count">
            <template slot-scope="{ row }">
              {{ row.count }}
            </template>
          </el-table-column>

          <el-table-column label="邀请人" min-width="100">
            <template slot="header">
              <div
                class="flex-center hidetext"
                style="float: left; margin-top: 5px"
              >
                邀请人
                <helpIcon
                  style="margin-left: 6px"
                  prompt="当学员被不同人邀请时，只显示最新的一个邀请人"
                ></helpIcon>
              </div>
            </template>
            <template slot-scope="{ row }">
              <div class="membership" v-if="row.invitee">
                <div class="tu">
                  <img
                    style="width: 22px; height: 22px"
                    :src="row.invitee_photo"
                    alt=""
                  />
                </div>
                <div
                  class="right"
                  :title="
                    row.invitee_remarks
                      ? row.invitee + '(' + row.invitee_remarks + ')'
                      : row.invitee
                  "
                >
                  {{ row.invitee }}

                  <span v-if="row.invitee_remarks">
                    ({{ row.invitee_remarks }})
                  </span>
                </div>
              </div>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column label="类型" prop="count">
            <template slot-scope="{ row }">
              <span v-if="row.new==1">新学员</span>
              <span v-if="row.new==2">老学员</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right" min-width="100px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="detailRecord(row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <el-drawer title="详情" size="558px" :visible.sync="drawer">
      <div slot="title" class="title">
        详情
        <span class="tip">学员使用多个设备同时在线，在线时长会重复记录</span>
      </div>
      <div class="drawer_contain" v-if="drawer">
        <div class="student_info">
          <div class="student">
            <div class="tu">
              <img
                style="width: 100%; height: 100%"
                :src="selected.uphoto"
                alt=""
              />
            </div>
            <div
              class="name"
              :title="
                selected.student_remarks
                  ? selected.uname + '(' + selected.student_remarks + ')'
                  : selected.uname
              "
            >
              {{ selected.uname }}

              <span v-if="selected.student_remarks">
                ({{ selected.student_remarks }})
              </span>
            </div>
          </div>
          <div class="btns flex-center">
            <el-button type="text" size="medium" @click="fenzu">分组</el-button>

            <span class="sp_driver">|</span>

            <tagselect
              :tag="selected.tag"
              :uids="selected.uid"
              :isLive="true"
              :nothing="true"
              @tagChange="tagChange"
            >
              <el-button type="text" slot="opencouse" size="medium">
                打标签
              </el-button>
            </tagselect>
          </div>
        </div>
        <div class="list_contain">
          <pagination2
            :option="post"
            url="/openClass/studentDetails"
            ref="childDialogBox"
            @complete="complete"
            :allFn="true"
          >
            <template v-slot:default="{ tableData }">
              <el-table
                ref="dialogTable"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="tableData"
                style="width: 100%"
              >
                <el-table-column label="进入时间" min-width="100">
                  <template slot-scope="{ row }">
                    {{ row.c_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}
                  </template>
                </el-table-column>

                <el-table-column label="在线时长">
                  <template slot-scope="{ row }">
                    {{ row.visit_duration | formatTimeLength }}
                  </template>
                </el-table-column>

                <el-table-column label="在线终端">
                  <template slot-scope="{ row }">
                    {{ row.user_from | getuserFrom }}
                  </template>
                </el-table-column>

                <el-table-column label="地理位置" prop="province">
                  <template slot-scope="{ row }">
                    {{ row.province }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
        </div>
      </div>
    </el-drawer>

    <!-- 分组 -->
    <category
      v-if="showCategory"
      :groupList="options3"
      :selectedData="showCategory2"
      @close="showCategory = false"
      @reset="reset"
    ></category>
  </div>
</template>

<script>
import tagselect from '@/components/tagselect/index.vue'
import category from '@/components/tagselect/category'
import helpIcon from '@/components/helpIcon'
export default {
  name: 'studentData',
  props: {
    isExportAll:Boolean,
  },
  components: {
    category,
    tagselect,
    helpIcon,
  },

  data() {
    return {
      showCategory2: {},

      showCategory: false,

      selected: {},

      drawer: false,

      options3: [],

      post: {
        uid: '',
        live_history_id: this.$route.query.sl_id,
        search: '',
        new:0,
        type: '1',
      },
    }
  },

  computed: {
    placeholder() {
      return this.post.type == 1 ? '输入学员名称搜索' : '输入邀请人搜索'
    },
  },

  created() {
    this.getGroupList()
  },

  filters: {
    getuserFrom(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '微信H5'
          break
        case 2:
          text = '小程序'
          break
        case 3:
          text = '电脑'
          break

        default:
          break
      }
      return text
    },
  },

  methods: {
    // 导出
    exportTable() {
      if (this.count > 0) {
        const confirmStr = this.isExportAll?'确定要导出所有数据？':`确定要导出当前${this.count}条数据？`
        this.$confirm(confirmStr, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(_.assign({}, this.post))
          this.$store.dispatch(
            'open',
            `${
              location.origin
            }/openClass/studentDataExportExcel?query=${encodeURIComponent(
              query
            )}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    // 获取列表数据
    complete1({ data }) {
      this.count = data.length
    },

    complete(val) {
      // this.count = val.infoList.l
      this.selected = val.infoList
    },

    // 获取机构分组
    getGroupList() {
      this.$http({
        url: '/pcUser/getGroupList',
        callback: ({ data }) => {
          this.options3 = data.map(item => {
            return {
              label: item.gname,
              value: Number(item.gid),
              count: item.number,
            }
          })
        },
      })
    },

    tagChange() {
      //   this.$refs.childDialogBox.reset()
    },

    reset() {
      this.getGroupList()
      this.$refs.childDialogBox.reset()
    },

    //分组
    fenzu() {
      this.showCategory2 = {
        uid: this.selected.uid,
        gid: this.selected.gid,
        uname: this.selected.uname,
      }
      this.$nextTick(() => {
        this.showCategory = true
      })
    },

    detailRecord(row) {
      this.post.uid = row.uid
      this.$nextTick(() => {
        this.drawer = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#studentData {
  margin: 0 20px 20px;

  ::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    background-color: #fff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
  ::v-deep .search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .el-select .el-input {
      width: 130px;
    }
    .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
  }
  .title {
    font-size: 14px;
    color: #333333;
    .tip {
      margin-left: 5px;
      font-size: 12px;
      color: #ff3535;
    }
  }
  ::v-deep .el-drawer__body {
    overflow-y: auto;
  }
  ::v-deep .el-drawer__header {
    margin-bottom: 0 !important;
  }
  .drawer_contain {
    .list_contain {
      margin: 0 20px;
    }
    .student_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px;
      box-sizing: border-box;
      padding: 10px;
      height: 49px;
      background: #f5f5f5;
      border-radius: 1px;
      .student {
        display: flex;
        align-items: center;
        .tu {
          width: 30px;
          height: 30px;
          border: 5px;
          background: #d8d8d8;
          margin-right: 10px;
        }
        .name {
          width: 364px;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .btns {
        .sp_driver {
          color: rgba(27, 157, 151, 1);
          margin: 0 5px;
          font-size: 16px;
        }
      }
    }
  }
  .membership {
    display: flex;
    align-items: center;
    .tu {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .right {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>
