<template>
  <div id="dataStatistics">
    <div class="first_container">
      <el-row>
        <el-col :span="8">名称：{{ liveHead.open_class_name }}</el-col>
        <el-col :span="8">
          时间：{{ liveHead.start_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}
        </el-col>
        <el-col :span="8" class="inright">
          时长： {{ liveHead.push_duration | formatTimeLength }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">学习人数： {{ liveHead.online }}</el-col>
        <el-col :span="8">打赏/送礼：{{ liveHead.price }}</el-col>
        <el-col :span="8" class="inright">
          类型：{{ liveHead.live_mode == 1 ? '标准直播' : '快直播' }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">直播老师： {{ liveHead.uname }}</el-col>
      </el-row>
    </div>
    <div class="selectContain">
      <el-tabs v-model="activeName">
        <el-tab-pane label="学员数据" name="1">
          <student-data v-if="activeName == 1" :isExportAll="true"></student-data>
        </el-tab-pane>
        <el-tab-pane label="直播数据" name="2">
          <dataAnalysis-detail v-if="activeName == 2"></dataAnalysis-detail>
        </el-tab-pane>
        <el-tab-pane label="打赏/送礼数据" name="3">
          <gift-data v-if="activeName == 3"></gift-data>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import dataAnalysisDetail from '@/components/dataAnalysis/dataAnalysisDetail'
import studentData from './studentData'
import giftData from './giftData'

export default {
  name: 'dataStatistics',

  components: {
    studentData,
    giftData,
    dataAnalysisDetail,
  },

  data() {
    return {
      activeName: '1',
      liveHead: {},
    }
  },

  created() {
    this.getliveHead()
  },

  methods: {
    getliveHead() {
      this.$http({
        url: '/openClass/liveHead',
        data: {
          live_history_id: this.$route.query.sl_id,
        },
        callback: ({ data }) => {
          this.liveHead = data
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#dataStatistics {
  .first_container {
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
    line-height: 12px;
    padding: 20px;
    background-color: #fff;
    .standard {
      float: right;
      color: #1b9d97;
      cursor: pointer;
    }
    .el-row {
      margin-bottom: 23px;
    }
    .el-row:last-child {
      margin-bottom: 0;
    }
    .inright {
      display: flex;
      justify-content: flex-end;
    }
  }
  ::v-deep .selectContain {
    background-color: #fff;
    margin-top: 20px;
    .el-tabs__nav-scroll {
      padding: 0 20px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }
}
</style>