<template>
  <div id="giftData">
    <div class="search">
      <el-input
        v-model="post.search"
        size="medium"
        style="width: 218px"
        placeholder="输入学员名称搜索"
      ></el-input>
    </div>

    <pagination2
      :option="post"
      url="/openClass/giftDataList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          ref="dialogTable"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column label="学员" min-width="100">
            <template slot-scope="{ row }">
              <div class="membership">
                <div class="tu">
                  <img
                    style="width: 22px; height: 22px"
                    :src="row.uphoto"
                    alt=""
                  />
                </div>
                <div
                  class="right"
                  :title="
                    row.student_remarks
                      ? row.uname + '(' + row.student_remarks + ')'
                      : row.uname
                  "
                >
                  {{ row.uname }}

                  <span v-if="row.student_remarks">
                    ({{ row.student_remarks }})
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="打赏金额(元)"
            prop="reward_price"
            min-width="90px"
          >
            <template slot-scope="{ row }">￥{{ row.reward_price }}</template>
          </el-table-column>

          <el-table-column
            label="打赏次数(次)"
            prop="reward_count"
            min-width="90px"
          >
            <template slot-scope="{ row }">
              {{ row.reward_count }}
            </template>
          </el-table-column>

          <el-table-column
            label="送礼金额(元)"
            prop="gift_price"
            min-width="90px"
          >
            <template slot-scope="{ row }">￥{{ row.gift_price }}</template>
          </el-table-column>

          <el-table-column label="送礼次数(次)" prop="gift_count">
            <template slot-scope="{ row }">
              {{ row.gift_count }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right" min-width="100px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="detailRecord(row)">
                详细记录
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <el-drawer title="详细记录" size="558px" :visible.sync="drawer">
      <div slot="title" class="title">详细记录</div>
      <div class="drawer_contain" v-if="drawer">
        <div class="student_info">
          <div class="student">
            <div class="tu">
              <img
                style="width: 100%; height: 100%"
                :src="selected.uphoto"
                alt=""
              />
            </div>
            <div
              class="name"
              :title="
                selected.student_remarks
                  ? selected.uname + '(' + selected.student_remarks + ')'
                  : selected.uname
              "
            >
              {{ selected.uname }}

              <span v-if="selected.student_remarks">
                ({{ selected.student_remarks }})
              </span>
            </div>
          </div>
          <div class="btns flex-center">
            <el-button type="text" size="medium" @click="fenzu">分组</el-button>

            <span class="sp_driver">|</span>

            <tagselect
              :tag="selected.tag"
              :uids="selected.uid"
              :isLive="true"
              :nothing="true"
              @tagChange="tagChange"
            >
              <el-button type="text" slot="opencouse" size="medium">
                打标签
              </el-button>
            </tagselect>
          </div>
        </div>
        <div class="list_contain">
          <pagination2
            :option="post"
            url="/openClass/giftData"
            ref="childDialogBox"
            @complete="complete"
            :allFn="true"
          >
            <template v-slot:default="{ tableData }">
              <el-table
                ref="dialogTable"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="tableData"
                style="width: 100%"
              >
                <el-table-column label="打赏/送礼" min-width="100">
                  <template slot-scope="{ row }">
                    <div class="membership">
                      <div class="tu">
                        <img v-if="row.icon" :src="row.icon" alt="" />
                        <img
                          v-else
                          src="~@ass/img/1.4.3.4/icon_dsbs@2x.png"
                          alt=""
                        />
                      </div>
                      <div class="right" :title="row.name">
                        {{ row.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="打赏时间" prop="order_time">
                  <template slot-scope="{ row }">
                    {{ row.order_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}
                  </template>
                </el-table-column>

                <el-table-column label="金额(元)" prop="price">
                  <template slot-scope="{ row }">
                    {{ row.price }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
        </div>
      </div>
    </el-drawer>

    <!-- 分组 -->
    <category
      v-if="showCategory"
      :groupList="options3"
      :selectedData="showCategory2"
      @close="showCategory = false"
      @reset="reset"
    ></category>
  </div>
</template>

<script>
import tagselect from '@/components/tagselect/index.vue'
import category from '@/components/tagselect/category'
export default {
  name: 'giftData',

  components: {
    category,
    tagselect,
  },

  data() {
    return {
      showCategory2: {},

      showCategory: false,

      selected: {},

      drawer: false,

      options3: [],

      post: {
        uid: '',
        live_history_id: this.$route.query.sl_id,
        search: '',
      },
    }
  },

  created() {
    this.getGroupList()
  },

  methods: {
    complete(val) {
      this.selected = val.infoList
    },

    // 获取机构分组
    getGroupList() {
      this.$http({
        url: '/pcUser/getGroupList',
        callback: ({ data }) => {
          this.options3 = data.map(item => {
            return {
              label: item.gname,
              value: Number(item.gid),
              count: item.number,
            }
          })
        },
      })
    },

    tagChange() {
      //   this.$refs.childDialogBox.reset()
    },

    reset() {
      this.getGroupList()
      this.$refs.childDialogBox.reset()
    },

    //分组
    fenzu() {
      this.showCategory2 = {
        uid: this.selected.uid,
        gid: this.selected.gid,
        uname: this.selected.uname,
      }
      this.$nextTick(() => {
        this.showCategory = true
      })
    },
    detailRecord(row) {
      this.post.uid = row.uid
      this.$nextTick(() => {
        this.drawer = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#giftData {
  margin: 0 20px 20px;
  ::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    background-color: #fff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
  .search {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .title {
    font-size: 14px;
    color: #333333;
  }
  ::v-deep .el-drawer__body {
    overflow-y: auto;
  }
  ::v-deep .el-drawer__header {
    margin-bottom: 0 !important;
  }
  .drawer_contain {
    .list_contain {
      margin: 0 20px;
    }
    .student_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px;
      box-sizing: border-box;
      padding: 10px;
      height: 49px;
      background: #f5f5f5;
      border-radius: 1px;
      .student {
        display: flex;
        align-items: center;
        .tu {
          width: 30px;
          height: 30px;
          border: 5px;
          background: #d8d8d8;
          margin-right: 10px;
        }
        .name {
          width: 364px;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .btns {
        .sp_driver {
          color: rgba(27, 157, 151, 1);
          margin: 0 5px;
          font-size: 16px;
        }
      }
    }
  }
  .membership {
    display: flex;
    align-items: center;
    .tu {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
  }
}
</style>
